import axios from "@/utils/axios";
import { reactive, watch, ref } from "vue";
import { data } from "@/utils/bd/getDocument";

const viewOkno = ref(false);
const loadingProcess = ref(false);
var dataArray = reactive([]);
var objectData = {};

const getSearchCollection = async (data) => {
  try {
    viewOkno.value = true;
    loadingProcess.value = false;
    var getDocument = await axios.post("/bd/", {
      collection: data.collection,
    });
    dataArray.value = getDocument.data;
    objectData = {
      return: data.return,
      objectadd: data.objectadd,
      indexline: data.indexline,
      objectaddname: data.objectaddname,
    };
    loadingProcess.value = true;
    return;
  } catch (e) {
    console.log(e);
  }
};

const addObjectToDocument = (document) => {
  if (objectData.indexline !== null) {
    data.value[objectData.objectadd][objectData.indexline][
      objectData.objectaddname
    ] = document[objectData.return];
  }
};

export {
  viewOkno,
  loadingProcess,
  getSearchCollection,
  dataArray,
  objectData,
  addObjectToDocument,
};
