import axios from "@/utils/axios";
import { data, save } from "@/utils/bd/getDocument";
import { sendNotification } from "@/utils/notification";
import router from "@/router";
import { ref } from "vue";

const change = ref(false);
const updateFullDocument = async () => {
  try {
    var update = await axios.post(
      `/bd/updatefulldocument/${router.currentRoute.value.meta.collection}/${data.value._id}`,
      data.value
    );
    save.value = false;
    change.value = true;
    setTimeout(() => (change.value = false), 500);
    sendNotification({
      title: "Отлично",
      text: "Документ изменен и сохранен",
    });
  } catch (e) {
    console.log(e);
    sendNotification({
      title: "Ошибка",
      text: "Попробуйте еще раз",
      type: "error",
    });
  }
};

const updateFieldDocument = async () => {
  try {
    //  var update = await axios.post(`/updatefulldocument/${router.currentRoute.value.meta.collection}/${data.value._id}`,data.value)
    //  console.log(update)
    //  save.value = false
    sendNotification({
      title: "Отлично",
      text: "Документ изменен и сохранен",
    });
  } catch (e) {
    console.log(e);
    sendNotification({
      title: "Ошибка",
      text: "Попробуйте еще раз",
      type: "error",
    });
  }
};

export { updateFullDocument, updateFieldDocument, change };
