<template>
  <div
    class="onenot"
    :class="{ error: data.type == 'error', removeokno: close }"
  >
    <div class="close" @click="deletenotification()">X</div>
    <h3>{{ data.title }}</h3>
    <p>{{ data.text }}</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { deleteNorification } from "@/utils/notification";

    const props = defineProps(['not']);
    const data = ref(props.not);
    const close = ref(false);
    const deletenotification = () => {
      close.value = true;
      setTimeout(() => {
        deleteNorification(data.id);
      }, 200);
    };
    onMounted(() => {
      setTimeout(() => {
        deletenotification();
      }, 2000);
    });

</script>
<style scoped>
.onenot {
  box-shadow: 2px 2px 10px 2px rgba(11, 10, 10, 0.2);
  box-shadow: 2px 2px 10px 2px rgb(11 10 10 / 20%);
  background: rgb(255 255 255 / 90%);
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 250px;
  animation: scale-in-hor-right 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  backdrop-filter: blur(1px);
}
.removeokno {
  animation: scale-in-hor-right-end 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}
@keyframes scale-in-hor-right {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes scale-in-hor-right-end {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.close {
  opacity: 0;
  transition: 200ms;
  position: absolute;
  right: 10px;
  margin-top: -5px;
  font-weight: 100;
  font-size: 14px;
  transform: rotateX(33deg);
  color: var(--blue);
}
.error .close,
.error h3,
.error p {
  color: var(--white);
}
.onenot:hover .close {
  opacity: 1;
}

h3 {
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 5px;
}
p {
  font-weight: 100;
}

.error {
  background-color: var(--red);
}
</style>
