import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/clubs/club",
    name: "clubs-club",
    title: "Клубы Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Клубы единоборств",
        item: defineAsyncComponent(() =>
          import("@/components/site/club/item.vue")
        ),
      },
      collection: "club",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию клуба",
        placeholder: "введите название клуба",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/clubs/club/:id",
    name: "clubs-club-id",
    title: "Клубы Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "club",
      template: {
        main: [
          "Title",
          "Infoclub",
          "Images",
          "Editor",
          "Addarticle",
          "Seo",
          "ClientClub",
          "Deletedocument",
        ],
        sidebar: ["Status", "Cityclub"],
      },
    },
  },
  {
    path: "/clubs/cityclub",
    name: "clubs-cityclub",
    title: "Города клубов Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Города",
        item: defineAsyncComponent(() =>
          import("@/components/site/club/catitem.vue")
        ),
      },
      collection: "cityclub",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию города",
        placeholder: "введите название города",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/clubs/cityclub/:id",                                                                                                                                                             
    name: "clubs-cityclub-id",
    title: "Города клубов Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "cityclub",
      populate: [],
      template: {
        main: [                                                                                                                                                                 
          "Title",
          "Images",
          "Editor",
          "Seo",
          "Deletedocument",
        ],
        sidebar: ["Sort"],
      },
    },
  }
];
