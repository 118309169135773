import axios from "@/utils/axios";
import router from "@/router";
import { reactive, watch, ref,computed,shallowRef } from "vue";
import Template from "@/components/page";
import {languages} from '@/utils/general'


const data = reactive({});
const save = ref(false);
var viewdocument = false;
const componentmain = shallowRef({})
const componentsidebar = shallowRef({})
const get = async () => {
  try {

    save.value = false;
    viewdocument = false;
    var getDocument = await axios.post("/bd/", {
      collection: router.currentRoute.value.meta.collection,
      filter: { _id: router.currentRoute.value.params.id },
      populate: router.currentRoute.value.meta.populate,
      lean:languages.value == 'ru' ? false : true
    });
    data.value = getDocument.data[0];
    watch([data], () => {
      if (viewdocument) {
        save.value = true;
      }
    });  
    setTimeout(() => (viewdocument = true), 100);
    return { data: computed(() => data) };
  } catch (e) {
    console.log(e);
  }
};

const componentos = () => {
  componentmain.value = {}
  componentsidebar.value = {}
  var main = router.currentRoute.value.meta.template.main;
  var sidebar = router.currentRoute.value.meta.template.sidebar;
  for (let one of main) {
    if (Object.keys(Template).indexOf(one) !== -1) {
      componentmain.value[one] = Template[one];
    }
  }

  for (let one of sidebar) {
    if (Object.keys(Template).indexOf(one) !== -1) {
      componentsidebar.value[one] = Template[one];
    }
  }
};

const getOther = async (d) => {
  try {
    var getDocument = await axios.post("/bd/", d);
    return { data: getDocument.data };
  } catch (e) {
    console.log(e);
  }
};

const createDocument = async (d) => {
  try {
    var getDocument = await axios.post("/bd/add", d);
    return getDocument;
  } catch (e) {
    console.log(e);
  }
};

const deleteDocument = async (d) => {
  try {
 
    if(d.collection == 'category'){
     
      var getDocument = await axios.post("/bd/update", {
        collection:'model',
        filter: { category: {$in:[d.id,...data.value.childrencategory]}},
        set:{$pull:{category:{$in:[d.id,...data.value.childrencategory]}}}
      })

      if(!data.value.parent){
        var getDocument = await axios.post("/bd/update", {
          collection:'category',
          filter: { childrencategory: {$in:[d.id]}},
          set:{$pull:{childrencategory:{$in:[d.id]}}}
        })
      }
      
    }
    var getDocument = await axios.post("/bd/delete", {
      id:d.id,
      collection:d.collection
    });
    return getDocument;
   
  } catch (e) {
    console.log(e);
  }
};

const updateDocument = async (data) =>{
      try{

        var getDocument = await axios.post("/bd/update",data)
        return getDocument;


      }
      catch(e){
        console.log(e);

      }

}

const search = async (d) => {
  try {
    var getDocument = await axios.post(
      "/bd/",
      {
        collection: d.collection,
        filter: d.filter || {},
        populate: d.populate || [],
        ignore: d.ignore || {},
        sort: d.sort || {},
      },
      { headers: { loading: false } }
    );
    return getDocument;
  } catch (e) {
    console.log(e);
  }
};


const copyDocument = async (d) => {
  try{
    var copyDocumant = await axios.post(
      "/bd/copy",
      {
        collection: d.collection,
        _id: d._id,
      },
      { headers: { loading: true } }
    );
    router.push(d.slug + `${copyDocumant._id}`)
    return 'ok';


  }
  catch(e){


  }
}

const translate = async (value,text,update = false) => {
  try{
    
    var perevod = await axios.post('/translate/google',{
     text:text
    })
    value['en'] = perevod
    return 

  }
  catch(e){
    console.log(e)
  }

} 

export {
  get,
  data,
  componentos,
  componentmain,
  componentsidebar,
  save,
  getOther,
  createDocument,
  deleteDocument,
  search,
  updateDocument,
  copyDocument,
  translate
};
