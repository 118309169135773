import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import AppLayout from "./layouts/AppLayout";

createApp(App)
  .use(store)
  .use(router)
  .component("AppLayout", AppLayout)
  .mount("#app");
