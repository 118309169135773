import store from "@/store";

const sendNotification = (data) => {
  store.dispatch("general/addnotification", data);
};
const deleteNorification = (data) => {
  store.dispatch("general/removenotification", data);
};
const viewNotification = (data) => {
  return store.getters["general/notification"];
};
export { sendNotification, deleteNorification, viewNotification };
