import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/select/selectgrafik",
    name: "select-grafik",
    title: "График начислений",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "График начислений",
        item: defineAsyncComponent(() =>
          import("@/components/select/item.vue")
        ),
      },
      collection: "selectgrafik",
      populate: [{path:'client',select:[]}],
      filter: {
        object: ["lastname", "phone","email","webpush"],
        text: "Поиск клиента",
        placeholder: "Введите номер телефона или фамилию клиента",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/select/selectgrafik/:id",
    name: "select-grafik-id",
    title: "Клиенты Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "selectgrafik",
      populate: ["client"],
      template: {
        main: ["Selectclient","Grafik"],
        sidebar: ["Status","Infoselect"],
      },
    },
  },
];
