import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/order/:id",
    name: "order-id-template",
    title: "Заказы Ultimatum Store",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "order",
      populate: [
          {
          path: "items.productid",
          select: [
            "name",
            "activityspecifications",
            "specifications.artikul",
            "specifications.name",
            "specifications.barcode",
            "specifications._id",
            "images",
            "price",
            "artikul",
            "barcode",
            'tnved',
            'model'
          ],
          populate:[{
            path:'model',select:['marking']
          }]
        },
        { path: "delivery" },
        { path: "payment" },
      ],
      template: {
        main: ["OrderEditor","OrderContragent","OrderProduct","OrderDelivery","OrderPayment"],
        sidebar: ["Status"],
      },
    },
  },
  {
    path: "/order",
    name: "order",
    title: "Заказы Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Заказы",
        item: defineAsyncComponent(() => import("@/components/order/item.vue")),
      },
      collection: "order",
      populate: [
        {
          path: "contragent",
          select: ["firstname", "lastname", "phone", "avatar"],
        },
        {
          path: "items.productid",
          select: [
            "name",
            "activityspecifications",
            "specifications.artikul",
            "specifications.name",
            "specifications.barcode",
            "specifications._id",
            "images",
            "price",
            "artikul",
            "barcode",
            'tnved'
          ],
        },
        { path: "delivery" },
        { path: "payment" },
      ],
      sort:{ordernumber:-1},
      viewdocument:{type:{$ne:'certificates'}},
      filter: {
        object: ["ordernumber","contragent.phone"],
        text: "Найти заказ по номеру",
        placeholder: "введите номер заказа",
         menufilter: [
          { name: "Все", key: "channel", action:['ultimatum-store','ultimatum-com','ultimatum-shop','ultimatum-sportmaster','ultimatum-crm']},
          { name: "Cайт(store)", key: "channel", action: "ultimatum-store"},
          { name: "Cайт(com)", key: "channel", action: "ultimatum-com"},
          { name: "Магазин", key: "channel", action: "ultimatum-shop"},
          { name: "Спортмастер", key: "channel", action: "ultimatum-sportmaster"},
          { name: "Crm", key: "channel", action: "ultimatum-crm"},
        ],
      },
    },
  },
  {
    path: "/returnorder",
    name: "returnorder",
    title: "Возвраты Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Возвраты",
        item: defineAsyncComponent(() =>
          import("@/components/service/payment/item.vue")
        ),
      },
      collection: "returnorder",
      populate: [],
      filter: {
        object: ["id"],
        text: "Найти по номеру возврата",
        placeholder: "введите номер возврата",
        menufilter: [
          { name: "Все", key: "status", action: true },
          { name: "Ожидают", key: "status", action: false },
          { name: "Исполнены", key: "status", action: false },
        ],
      },
    },
  },
];
