<template>
  <div class="fullpage">
    <p class="viewcontent" @click="viewOkno = false">Закрыть</p>
    <div class="contentpreview">
      <input type="text" placeholder="Найти" />
      <div
        class="oneblock"
        v-for="(one, index) of dataArray.value"
        :key="'oneBlock' + index"
        @click="addObjectToDocument(one)"
        v-if="loadingProcess"
      >
        <p>{{ one.name }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import {
  viewOkno,
  loadingProcess,
  dataArray,
  addObjectToDocument,
} from "@/utils/bd/searchDocument";

    const preview = ref(false);
    const imgAPI = process.env.VUE_APP_IMAGES;
    
</script>

<style scoped>
.fullpage {
  position: fixed;
  right: 0;
  top: 0;
  width: calc(320px - 20px);
  background: var(--white);
  height: calc(100vh - 60px);
  padding: 10px;
  background: var(--white);
  z-index: 9999;
}

.viewcontent {
  font-weight: 100;
  color: var(--blue);
  cursor: pointer;
  transition: 200ms;
  padding-bottom: 10px;
}
.contentpreview {
  overflow: scroll;
  height: 100%;
}
.viewcontent:hover {
  color: var(--green);
}

input {
  padding: 5px;
  margin-bottom: 10px;
}

.oneblock {
  border: 1px solid var(--blackgrey);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  width: calc(100% - 30px);
  cursor: pointer;
  transition: 200ms;
}

.oneblock:hover {
  box-shadow: var(--boxshadowhover);
}
</style>
