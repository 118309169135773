<template>
  <div>
    <Headers />
    <div class="layoutblock">
      <Sidebar />
      <Suspense>
        <div class="contentblock">
          <slot />
        </div>
      </Suspense>
      <Savebutton />
    </div>
  </div>
</template>

<script setup>
import Headers from "@/components/layouts/headers";
import Sidebar from "@/components/layouts/sidebar.vue";
import Savebutton from "@/components/general/savebutton.vue";
import { onMounted } from "vue";
import {languages} from "@/utils/general"

    onMounted(() => {
      var local = localStorage.getItem('locale')
      if(local){
        if(local !== languages.value){
          languages.value = local
        }
      }
    })

</script>

<style scoped>
.layoutblock {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}
.contentblock {
  width: calc(100% - 10px);
}
</style>
