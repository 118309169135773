import axios from "axios";
import store from "@/store";
import {languages} from "@/utils/general"
axios.defaults.baseURL = `${process.env.VUE_APP_API}`;
axios.defaults.headers.post["Content-Type"] = "application/json";
languages.value = localStorage.getItem('locale')
axios.defaults.headers.post["Accept-Language"] = languages.value;
axios.defaults.headers["loading"] = true;
axios.interceptors.request.use(
  (config) => {
    if (config.headers.loading) {
      store.dispatch("general/loading", true);
    }
    return config;
  },
  (error) => {
    store.dispatch("general/loading", false);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    store.dispatch("general/loading", false);
    return res.data;
  },
  (error) => {
    store.dispatch("general/loading", false);
    return Promise.reject(error);
  }
);

export default axios;
