import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/arm/user",
    name: "arm-user",
    title: "Пользователи ARM Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Админка",
        item: defineAsyncComponent(() =>
          import("@/components/arm/user/item.vue")
        ),
      },
      collection: "armuser",
      populate: ['role'],
      filter: {
        object: ["lastname", "phone"],
        text: "Поиск пользователя",
        placeholder: "Введите номер телефона или фамилию пользователя",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/arm/user/:id",
    name: "arm-user-id",
    title: "Пользователи arm",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "armuser",
      populate: [],
      template: {
        main: ['Infouserarm'],
        sidebar: ["Status"],
      },
    },
  },
  {
    path: "/arm/role",
    name: "arm-role",
    title: "Роли ARM Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Роли",
        item: defineAsyncComponent(() =>
         import("@/components/arm/role/catitem.vue")
        ),
      },
      collection: "armrole",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск роли",
        placeholder: "Введите название роли",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/arm/role/:id",
    name: "arm-role-id",
    title: "Роли arm",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "armrole",
      populate: [],
      template: {
        main: ['Title',"Slugarm"],
        sidebar: ["Status"],
      },
    },
  },
  {
    path: "/arm/point",
    name: "arm-point",
    title: "Точки ARM Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Роли",
        item: defineAsyncComponent(() =>
         import("@/components/arm/point/catitem.vue")
        ),
      },
      collection: "armpoint",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск по названию",
        placeholder: "Введите название",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/arm/point/:id",
    name: "arm-point-id",
    title: "Точки arm",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "armpoint",
      populate: [],
      template: {
        main: ['Title',"Pointarm"],
        sidebar: ["Status"],
      },
    },
  },
];