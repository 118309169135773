import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/product/model",
    name: "product-model",
    title: "Модели Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Модели",
        item: defineAsyncComponent(() =>
          import("@/components/product/model/item.vue")
        ),
      },
      collection: "model",
      populate: ["product"],
      filter: {
        object: ["name"],
        text: "Найти артикул",
        placeholder: "введите артикул",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/product/model/:id",
    name: "product-model-id",
    title: "Модели Ultimatum Store",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "model",
      populate: [
        { path: "product" },
        { path: "additionally.id", select: "name" },
      ],
      template: {
        main: ["Title", "Editor","Dopinfoblock","Dimensions","Razmeradditionally", "BlockProduct", "Seo","Deletedocument"],
        sidebar: ["Status", "Modeldop", "Category", "ViewStatick","Channel"],
      },
    },
  },
  {
    path: "/product/product",
    name: "product-product",
    title: "Товары Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Товары",
        item: defineAsyncComponent(() =>
          import("@/components/product/product/item.vue")
        ),
      },
      collection: "product",
      populate: ["model"],
      filter: {
        object: ["name"],
        text: "Найти по названию",
        placeholder: "введите название",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/product/product/:id",
    name: "product-product-id",
    title: "Товары Ultimatum Store",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "product",
      populate: [],
      template: {
        main: ["Title","Productartikul","Images","Colorproduct","Editor", "Specification","Tagsblock","Seo","Deletedocument"],
        sidebar: ["Status", "Saleblock", "Productdop","Datareli","ViewStatick"],
      },
    },
  },
  {
    path: "/product/category",
    name: "product-category",
    title: "Категории Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Категории",
        item: defineAsyncComponent(() =>
          import("@/components/product/category/item.vue")
        ),
      },
      collection: "category",
      populate: [{path:'childrencategory',select:['name']}],
      sort:{sortindex:1},
      sortview:true,
      viewdocument:{parent:true},
      filter: {
        object: ["name"],
        text: "Найти категорию",
        placeholder: "введите название",
      },
    },
  },
  {
    path: "/product/category/:id",
    name: "product-category-id",
    title: "Категории Ultimatum Store",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "category",
      populate: [],
      template: {
        main: [
        "Title",
        "Dopopisanie",
        "Oblozka",
        "Images",
        "Сhildrencategory",
        "Categoryproductblock",
        "Seo",
        "Deletedocument"
        ],
        sidebar: ["Template","Sort","Channel"],
      },
    },
  },

  {
    path: "/product/additionally",
    name: "product-additionally",
    title: "Объяснения для моделей Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Объяснения",
        item: defineAsyncComponent(() =>
          import("@/components/product/additionally/item.vue")
        ),
      },
      collection: "additionally",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию",
        placeholder: "введите название",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/product/additionally/:id",
    name: "product-additionally-id",
    title: "Объяснения Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "additionally",
      populate: [],
      template: {
        main: ["Title", "Editor", "Deletedocument"],
        sidebar: ["Status"],
      },
    },
  },

  {
    path: "/product/collection",
    name: "product-collection",
    title: "Коллекции Ultimatum Store",
    component: () => import("../template/pages/templateCollectionGrid.vue"),
    meta: {
      infocollection: {
        name: "Коллекции",
        item: defineAsyncComponent(() =>
          import("@/components/product/collection/item.vue")
        ),
      },
      collection: "collection",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти коллекцию",
        placeholder: "введите название",
      },
    },
  },
  {
    path: "/product/collection/:id",
    name: "product-collection-id",
    title: "Коллекции Ultimatum Store",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "collection",
      populate: [],
      template: {
        main: [
          "Title",
          "Dopopisanie",
          "Oblozka",
          "Images",
          "BlockProductAdd",
          "Seo",
          "Deletedocument",
        ],
        sidebar: ["Status", "Template","Sort","Channel"],
      },
    },
  },
];
