import { ref,computed,watch} from "vue";
import axios from "@/utils/axios";

const orderview = ref("");
const languages = ref('ru')

const typefile = (data) => {
  var ext = data.split(".");
  ext = ext[ext.length - 1].toLowerCase();
  var arrayExtensions = ["jpg", "jpeg", "png", "bmp", "gif"];
  if (arrayExtensions.lastIndexOf(ext) == -1) {
    return "video";
  } else {
    return "image";
  }
};

const lng = computed(() => {
  if(languages.value == 'ru'){
    return true
  }
  return false
})

const checkImgSrc = (data) => {};


const languagesSet = async (val) => {
  try{
    localStorage.setItem('locale', val)
    location.reload();
  }
  catch(e){}
} 


class paginationUtil {
  constructor(data) {
    this.limit = ref(data.limit);
    this.skip = ref(data.skip);
    this.total = ref(data.total);
    this.viewpage = ref(1);
    this.view = computed(() => {
      if (this.skip.value == 0) {
        return this.limit.value;
      }
      if (this.viewpage.value * this.limit.value > this.total.value) {
        return this.total.value;
      }
      return this.viewpage.value * this.limit.value;
    });
  }

  nav(type) {
    if (type == "next") {
      if (this.view.value !== this.total.value) {
        this.viewpage.value++;
        this.skip.value = this.view.value;
      }
    }
    if (type == "prev") {
      if (this.view.value !== this.limit.value) {
        this.viewpage.value--;
        this.skip.value = this.view.value;
      }
    }
  }
}

export { typefile, paginationUtil, orderview, checkImgSrc,languages,lng,languagesSet };
