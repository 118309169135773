<template>
  <transition name="slimeopacity">
    <div class="windowsinfo" v-if="loading">
      <div class="podblock">

        <div class="loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
        </div>

        <svg
          width="17"
          height="19"
          viewBox="0 0 17 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8695 15.9484L12.8726 19H4.12735L1.13049 15.9699V4.27015H0V0H7.41217V4.27015H6.29235V14.126L6.90025 14.7191H10.1211L10.7077 14.126V4.27015H9.58783V0H17V4.27015H15.8695V15.9484Z"
            fill="#2A4751"
          />
        </svg>

        <p>Идёт загрузка</p>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

    const store = useStore();
    const loading = computed(() => {
      return store.getters["general/loading"];
    });

</script>
<style scoped>
.windowsinfo {
  width: 100%;
  height: 100vh;
  z-index: 999999999999;
  background: rgb(255 255 255 / 70%);
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  backdrop-filter: blur(5px);
}

.loading {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 4px solid #bfc8ca;
  border-radius: 50%;
  border-top-color: var(--new);

  font-weight: bold;
  font-size: 19px;
  color: var(--new);
  display: grid;
  align-items: center;
  justify-items: center;
}

.podblock {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 5px;
}

.podblock p {
  font-weight: 100;
  margin-top: 10px;
}
.podblock svg{
    margin-top: -31px;
    width: 60px;
    height: 50px;
    position: absolute;
}

.podblock svg path {
  stroke: var(--blue);
    fill: var(--white);
    stroke-width: 0.5px;
}



.slimeopacity-enter-active {
  transition: all 200ms ease;
}
.slimeopacity-leave-active {
  transition: all 200ms;
}
.slimeopacity-enter,
.slimeopacity-leave-to {
  opacity: 0;
}

.loader {
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 2px;
  width: 100px;
  height: 100px;
}
.loader > div {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--blue);
  transform: scale(0);
  transform-origin: center center;
  animation: loader 2s infinite linear;
}
.loader > div:nth-of-type(1), .loader > div:nth-of-type(5), .loader > div:nth-of-type(9) {
  animation-delay: 0.4s;
}
.loader > div:nth-of-type(4), .loader > div:nth-of-type(8) {
  animation-delay: 0.2s;
}
.loader > div:nth-of-type(2), .loader > div:nth-of-type(6) {
  animation-delay: 0.6s;
}
.loader > div:nth-of-type(3) {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
