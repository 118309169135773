<template>
  <div>
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
</template>

<style>

@font-face {
  font-family: "Formular";
  src: url("/public/fontss/c3.woff2") format("woff2");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Formular";
  src: url("/public/fontss/c2.woff2") format("woff2");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Formular";
  src: url("/public/fontss/c3.woff2") format("woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Formular";
  src: url("/public/fontss/c4.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Formular";
  src: url("/public/fontss/c5.woff2") format("woff2");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

body {
  font-family: "Formular", "Arial", sans-serif;
  margin: 0;
  overflow: hidden scroll;
  touch-action: pan-x pan-y;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #000;
}

html {
  background: var(--lightgrey);
  transition: 500ms;
}

h2 {
  font-size: 18px;
  font-weight: 300;
  padding: 10px 0px 14px;
  letter-spacing: 2px;
  color: var(--black);
}

p {
  font-size: 13px;
  font-weight: 300;
  color: var(--black);
}

:root {
  --green: green;
  --white: #fff;
  --black: #222222;
  --grey: #f6f6f6;
  --blackgrey: #cfcfcf;
  --blue: #333;
  --red: #fe6b58;
  --boxshadowhover: 0px 0px 20px 0px rgb(0 0 0 / 23%);
  --boxshadowlight: 0 20px 27px 0 rgb(0 0 0 / 5%);
  --light: #fbf8f4;
  --lightgrey: #f8f8f9;
  --lightblue: #88989e;
  --input: #efefef;
}

:root.dark-theme {
  --green: green;
  --white: #222225;
  --input: #222225;
  --black: #a9a9b2;
  --grey: #f6f6f6;
  --blackgrey: #cfcfcf;
  --blue: #a2a5b9;
  --red: #fe6b58;
  --boxshadowhover: 0px 0px 20px 0px rgb(0 0 0 / 23%);
  --light: #262626;
  --lightgrey: #3c3a40;
  --lightblue: #88989e;
}

.padding {
  padding: 0px 10px;
  width: calc(100% - 20px);
}

input,
select,
textarea {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid var(--blackgrey);
  border-radius: 5px;
  transition: all 0.3s;
  background: var(--white);
  color: var(--black);
}
input:focus,
select:focus,
textarea:focus {
  box-shadow: var(--boxshadowhover);
}

input:focus-visible,
select:focus-visible,
textarea:focus-visible {
  outline: none;
  background: var(--white);
}

.blockinputp {
  display: grid;
  gap: 5px;
}
.blockinputp p {
  font-weight: 300;
  color: var(--blue);
}

.button {
  background: var(--blue);
  padding: 10px;
  color: var(--white);
  cursor: pointer;
  transition: 200ms;
  width: max-content;
  border-radius: 5px;
  font-weight: 300;
}
.button:hover {
  box-shadow: var(--boxshadowhover);
}

.activitygreen {
  background: var(--green);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slime-enter-active {
  transition: all 200ms ease;
}
.slime-leave-active {
  transition: all 200ms;
}
.slime-enter-from,
.slime-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slimeleft-enter-active {
  transition: all 200ms ease;
}
.slimeleft-leave-active {
  transition: all 200ms;
}
.slimeleft-enter-from,
.slimeleft-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slimeright-enter-active {
  transition: all 200ms ease;
}
.slimeright-leave-active {
  transition: all 200ms;
}
.slimeright-enter-from,
.slimeright-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slimedown-enter-active {
  transition: all 200ms ease;
}
.slimedown-leave-active {
  transition: all 200ms;
}
.slimedown-enter-from,
.slimedown-leave-to {
  transform: translateY(100%);
}

.slimeup-enter-active {
  transition: all 200ms ease;
}
.slimeup-leave-active {
  transition: all 200ms;
}
.slimeup-enter-from,
.slimeup-leave-to {
  transform: translateY(-100%);
}

.slimeopacity-enter-active {
  transition: all 1500ms ease;
}
.slimeopacity-leave-active {
  transition: all 1500ms;
}
.slimeopacity-enter-from,
.slimeopacity-leave-to {
  opacity: 0;
}

.svgblack path,
.svgblack rect {
  fill: var(--black);
}

.dark-theme .invert {
  filter: invert(0.7);
}

.buttonwidget {
  margin: 20px 0px;
  background: var(--input);
  height: 50px;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: 100;
  color: var(--black);
  border: 1.5px dashed var(--lightblue);
  border-radius: 10px;
  cursor: pointer;
  transition: 200ms;
}
.buttonwidget:hover {
  box-shadow: var(--boxshadowhover);
}

.nodisplay {
  display: none;
}

.slide {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 10px;
  overflow: auto;
  padding: 0px 0 20px;
  width: 100%;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track-piece {
  background: #ebe9f1;
}
::-webkit-scrollbar-thumb:vertical {
  height: 7px;
  background: var(--blue);
  border-radius: 7px;
  transition: 200ms;
}
::-webkit-scrollbar-thumb:vertical:hover {
  background: var(--blue);
  transition: 200ms;
}
::-webkit-scrollbar-thumb:horizontal {
  width: 7px;
  background: var(--blue);
}
::-webkit-scrollbar-thumb:horizontal:hover {
  background: var(--blue);
}

.titlewidget {
  background: var(--white);
  padding: 5px;
  border-radius: 7px;
  border: 1px solid var(--blackgrey);
  margin-bottom: 20px;
}

.titlewidget p {
  text-align: right;
  margin-bottom: 8px;
  font-weight: 300;
}

.custom-checkbox {
  transform: translateY(-10px);
}

input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

input[type="checkbox"]:checked + label,
input[type="checkbox"]:not(:checked) + label {
  position: relative;
  cursor: pointer;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
  border-radius: 2px;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  transition: all 0.2s ease;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
  left: 3px;
  top: 4px;
  width: 10px;
  height: 5px;
  border-radius: 1px;
  border-left: 4px solid var(--green);
  border-bottom: 4px solid var(--green);
  transform: rotate(-45deg);
}

input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.ql-editor p{
margin-bottom:20px !important;
}

.engblock{
  display: grid;
  gap:20px;
}

.engblockperevod{
  display: grid;
  grid-template-columns: 1fr auto;
  gap:20px;
}
.engblockperevod .buttonwidget{
  margin: 0;
  padding: 0px 10px;
}

</style>
