import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { useCookies } from "vue3-cookies";
import { save } from "@/utils/bd/getDocument";
const { cookies } = useCookies();
var user = cookies.get("user");

import productRoute from "./product";
import siteRoute from "./site";
import generalRoute from "./general";
import clientRoute from "./client";
import orderRoute from "./order";
import deliveryRoute from "./delivery";
import paymentRoute from "./payment";
import adminkaRoute from "./adminka";
import certificatesRoute from "./certificates";
import clubsRoute from "./clubs";
import armRoute from "./arm";
import subscriptionsRoute from "./subscriptions"
import selectRoute from "./select"

const routes = [
  ...productRoute,
  ...siteRoute,
  ...generalRoute,
  ...clientRoute,
  ...orderRoute,
  ...deliveryRoute,
  ...paymentRoute,
  ...adminkaRoute,
  ...certificatesRoute,
  ...clubsRoute,
  ...armRoute,
  ...subscriptionsRoute,
  ...selectRoute,
  {
    path: "/",
    name: "dashbord",
    component: () => import("../views/index.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from) => {
  if (!store.state.auth.user) {
    if (user) {
      try {
        await store.dispatch("auth/loadinguser", user);
        return true;
      } catch (e) {
        cookies.remove("user");
        return { name: "auth" };
      }
    }
    if (to.meta.auth) {
      return true;
    }
    return { name: "auth" };
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    save.value = false;
  }
  next();
});


router.beforeEach(async (to, from) => {
  if(store.state.auth.user) {
    if(!store.state.auth.user.bog){
    var views = to.name.replace('-id','')
    var fullmenu = []

    for(let one of store.state.auth.user.menu){
      if(one.children){
        for(let ch of one.children){
          fullmenu.push(ch)
        }
      }else{
        fullmenu.push(one)
      }


    }
    
    var proverka = fullmenu.filter(x => x.path == views)
    
    if(proverka.length == 0){



      if(to.name !== 'adminka-user-id'){
        if(to.name !== 'index'){
          return { name: "index" };
        }
      }



    }else{
      return true
    }
  }
}
return true
});

//router.afterEach((to, from) => {});

export default router;
