<template>
  <component :is="layout" :class="{ blur: loading }" class="animation">
    <slot />
  </component>
  <Loading />
  <div class="notifictions" v-if="notification.length > 0">
    <Notification v-for="s in notification" :not="s" :key="s.id" />
  </div>
  <Transition name="slimeright">
    <Scol v-if="viewOkno" />
  </Transition>
</template>

<script setup>
import AppLayoutDefault from "@/layouts/AppLayoutDefault";
import AppLayoutAuth from "@/layouts/AppLayoutAuth";
import Loading from "@/components/general/loading";
import Notification from "@/components/general/notification";
import Scol from "@/components/general/scol.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { viewOkno } from "@/utils/bd/searchDocument";

    const store = useStore();
    const loading = computed(() => {
      return store.getters["general/loading"];
    });
    const notification = computed(() => {
      return store.getters["general/notification"];
    });

    const layout = computed(() => {
      if (store.getters["auth/user"] !== null) {
        return AppLayoutDefault;
      } else {
        return AppLayoutAuth;
      }
    });

</script>

<style scoped>
.animation {
  transition: 500ms;
}
.blur {
  filter: blur(1px);
}
.notifictions {
  position: fixed;
  z-index: 999999999;
  display: grid;
  align-items: start;
  justify-items: end;
  top: 0;
  width: max-content;
  right: 0;
}
</style>
