import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/delivery",
    name: "delivery",
    title: "Доставки Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Доставки",
        item: defineAsyncComponent(() =>
          import("@/components/service/delivery/item.vue")
        ),
      },
      collection: "delivery",
      populate: [],
      filter: {
        object: ["id"],
        text: "Найти по трэку или номеру телефона",
        placeholder: "введите трэк или номер телефона клиента",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Исходящие", key: "status", action: true },
          { name: "Входящие", key: "status", action: false },
          { name: "Завершенные", key: "status", action: false },
        ],
      },
    },
  },
];
