export default [
  {
    path: "/",
    name: "index",
    title: "Административная панель Ultimatum Boxing",
    component: () => import("../views/index.vue"),
  },

  {
    path: "/auth",
    name: "auth",
    component: () => import("../views/auth.vue"),
    meta: {
      layout: "AppLayoutAuth",
      auth: true,
    },
  }
];
