import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/adminka/user",
    name: "adminka-user",
    title: "Админка Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Админка",
        item: defineAsyncComponent(() =>
          import("@/components/adminka/user/item.vue")
        ),
      },
      collection: "user",
      populate: [],
      filter: {
        object: ["lastname", "phone"],
        text: "Поиск пользователя",
        placeholder: "Введите номер телефона или фамилию пользователя",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/adminka/user/:id",
    name: "adminka-user-id",
    title: "Пользователи админкой",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "user",
      populate: [],
      template: {
        main: ["Editor","Menuadmin"],
        sidebar: ["Status","Bog","Infouseradmin"],
      },
    },
  },
];