<template>
  <div class="menuuser"></div>
</template>
<style scoped>
.menuuser {
  position: fixed;
  right: 0;
  background: rgb(255 255 255 / 86%);
  width: 320px;
  height: 100vh;
  top: 0;
  backdrop-filter: blur(1px);
  z-index: 9;
}
</style>
