import axios from "@/utils/axios";
import { menuuser } from "@/utils/menu"
const auth = {
  namespaced: true,
  state: {
    user: null,
    menu: [],
  },
  getters: {
    user: (state) => state.user,
    menu: (state) => state.menu,
  },
  mutations: {
    SET_USER(state, data) {
      state.user = data
      if(data.bog){
      state.menu = menuuser
      }else{
      state.menu = data.menu
      }
    },
  },
  actions: {
    async loadinguser({ commit }, token) {
      try {
        var user = await axios.post(
          "https://api.ultimatum.store/adminauth/token",
          { token: token }
        );
        commit("SET_USER", user);
        return true;
      } catch (e) {
        console.log(e);
        commit("SET_USER", null);
        throw e;
      }
    },
  },
};
export default auth;
