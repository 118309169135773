import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/certificates",
    name: "certificates",
    title: "Cертификаты Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Cертификаты",
        item: defineAsyncComponent(() => import("@/components/certificates/item.vue")),
      },
      collection: "order",
      populate: [
        {
          path: "contragent",
          select: ["firstname", "lastname", "phone", "avatar"],
        },
        { path: "delivery" },
        { path: "payment" },
      ],
      viewdocument:{type:'certificates'},
      filter: {
        object: ["code"],
        text: "Найти сертификат коду",
        placeholder: "введите код сертификата",
        menufilter: [],
      },
    },
  },
  {
    path: "/certificates/:id",
    name: "certificates-id",
    title: "Заказы Ultimatum Store",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "order",
      populate: [
        {
        path: "items.productid",
        select: [
          "name",
          "activityspecifications",
          "specifications.artikul",
          "specifications.name",
          "specifications.barcode",
          "specifications._id",
          "images",
          "price",
          "artikul",
          "barcode",
          'tnved',
          'model'
        ],
        populate:[{
          path:'model',select:['marking']
        }]
      },
      { path: "delivery" },
      { path: "payment" },
    ],
      template: {
        main: ["OrderEditor","OrderContragent","OrderProduct"],
        sidebar: ["Statuscertificat"],
      },
    },
  },
];
