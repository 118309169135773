import { createStore } from "vuex";
import general from "../store/general";
import auth from "../store/auth";

export default createStore({
  modules: {
    auth,
    general,
  },
});
