import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/subscriptions/noauth",
    name: "subscriptions-noauth",
    title: "Пользователи ARM Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Не клиенты",
        item: defineAsyncComponent(() =>
          import("@/components/arm/user/item.vue")
        ),
      },
      collection: "subscription",
      populate: [],
      filter: {
        object: [],
        text: "Поиск пользователя",
        placeholder: "Введите номер телефона или фамилию пользователя",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/subscriptions/auth",
    name: "subscriptions-auth",
    title: "Пользователи ARM Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Клиенты",
        item: defineAsyncComponent(() =>
          import("@/components/client/client/item.vue")
        ),
      },
      collection: "client",
      populate: [],
      filter: {
        object: ["lastname", "phone","email","webpush"],
        text: "Поиск пользователя",
        placeholder: "Введите номер телефона или фамилию пользователя",
        menufilter: [
          { name: "Подписанные", key: "email.newsletter", action: true },
          { name: "Неподписанные", key: {"email.mail":{$ne:null},"email.newsletter":false}, action: false },
          { name: "Без e-mail", key: "email.mail", action: null }
        ],
      },
    },
  },
  {
    path: "/arm/user/:id",
    name: "arm-user-id",
    title: "Пользователи arm",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "armuser",
      populate: [],
      template: {
        main: ['Infouserarm'],
        sidebar: ["Status"],
      },
    },
  },
];