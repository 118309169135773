const menuuser = [
    {
      path: "dashbord",
      name: "Дашборд",
      view: true,
      save:true,
      delete:true
    },
    {
      path: "order",
      name: "Заказы",
      view: true,
      save:true,
      delete:true
    },
    {
        path: "certificates",
        name: "Сертификаты",
        view: true,
        save:true,
        delete:true
      },
      {
        path: "returnorder",
        name: "Возвраты",
        view: true,
        save:true,
        delete:true
      },
    {
      path: "delivery",
      name: "Доставки",
      view: true,
      save:true,
      delete:true
    },
    {
      path: "payment",
      name: "Платежи",
      view: true,
      save:true,
      delete:true
    },
    {
      path: "avansandbonus",
      name: "Начисления",
      view: true,
      save:true,
      delete:true
    },
    {
      name: "Клиенты",
      view: true,
      path: "client-client",
      save:true,
      delete:true
    },
    {
      path: "product",
      name: "Продукты",
      view: true,
      children: [
        { path: "product-model", name: "Модели", view: true,  save:true,delete:true },
        { path: "product-product", name: "Товары", view: true,  save:true,delete:true },
        { path: "product-category", name: "Категории", view: true,  save:true,delete:true },
        { path: "product-collection", name: "Коллекции", view: true,  save:true,delete:true },
        { path: "product-additionally", name: "Объяснения", view: true,save:true,delete:true },
      ],
    },
    {
      path: "site",
      name: "Сайт",
      view: true,
      save:true,
      delete:true,
      children: [
        { path: "site-article", name: "Блог", view: true,  save:true,delete:true },
        { path: "site-page", name: "Страницы", view: true,  save:true,delete:true },
        { path: "site-vacancy", name: "Вакансии", view: true,  save:true,delete:true },
        { path: "site-article-category", name: "Категории блога", view: true,  save:true,delete:true },
        { path: "site-vacancy-category", name: "Категории вакансий",view: true,save:true,delete:true },
        { path: "site-history", name: "Истории",view: true,save:true,delete:true }
      ],
    },
    {
      path: "site-comment",
      name: "Комментарии",
      view: true,
      save:true,
      delete:true
    },
    {
      path: "subscriptions",
      name: "Подписки",
      view: true,
      save:true,
      delete:true,
      children: [
        { path: "subscriptions-noauth", name: "Не клиенты", view: true,  save:true,delete:true },
        { path: "subscriptions-auth", name: "Клиенты", view: true,  save:true,delete:true },
        { path: "subscriptions-push", name: "Push", view: true,  save:true,delete:true }
      ],
    },
    {
      path: "clubs",
      name: "Клубы",
      view: true,
      save:true,
      delete:true,
      children: [
        { path: "clubs-club", name: "Клубы", view: true,  save:true,delete:true },
       // { path: "clubs-client", name: "Люди", view: true,  save:true,delete:true },
        { path: "clubs-cityclub", name: "Города", view: true,  save:true,delete:true },
      ],
    },
    {
      path: "select",
      name: "Ultimatum Select",
      view: true,
      save:true,
      delete:true,
      children: [
        { path: "select-grafik", name: "График начислений", view: true,  save:true,delete:true },
        { path: "select-client", name: "Люди", view: true,  save:true,delete:true },
      ],
    },
    {
      path: "adminka",
      name: "Админка",
      view: true,
      children: [
        { path: "adminka-user", name: "Пользователи", view: true,  save:true,delete:true }
      ],
    },
    {
      path: "arm",
      name: "АРМ",
      view: true,
      children: [
        { path: "arm-user", name: "Пользователи", view: true,  save:true,delete:true },
        { path: "arm-role", name: "Роли", view: true,  save:true,delete:true },
        { path: "arm-point", name: "Точки продаж", view: true,  save:true,delete:true }
      ],
    },
  ]


export { menuuser };