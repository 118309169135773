<template>
  <header class="padding">
    <div class="logotip invert"><img src="@/assets/logo.svg" /></div>
    <div class="blockfunction">
      <div
        class="oneicons twogridicons"
        v-if="route.path.split('/').length > 3"
        @click="router.go(-1)"
      >
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <title />
          <g data-name="1" id="_1">
            <path
              d="M353,450a15,15,0,0,1-10.61-4.39L157.5,260.71a15,15,0,0,1,0-21.21L342.39,54.6a15,15,0,1,1,21.22,21.21L189.32,250.1,363.61,424.39A15,15,0,0,1,353,450Z"
            />
          </g>
        </svg>
        <p>НАЗАД</p>
      </div>

      <div class="oneicons">
        <svg
         
          id="Layer_1"
          style="enable-background: new 0 0 100 100; width: 38px"
          version="1.1"
          viewBox="0 0 100 100"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M87.114,39.605h-5.75v-5.75c0-1.104-0.896-2-2-2s-2,0.896-2,2v5.75h-5.75c-1.104,0-2,0.896-2,2s0.896,2,2,2h5.75v5.75  c0,1.104,0.896,2,2,2s2-0.896,2-2v-5.75h5.75c1.104,0,2-0.896,2-2S88.219,39.605,87.114,39.605z"
          />
          <path
            d="M9.058,36.096c3.34,0,6.058-2.713,6.058-6.048S12.397,24,9.058,24S3,26.713,3,30.048S5.718,36.096,9.058,36.096z M9.058,28  c1.135,0,2.058,0.919,2.058,2.048s-0.923,2.048-2.058,2.048S7,31.177,7,30.048S7.923,28,9.058,28z"
          />
          <path
            d="M95,28.05h-4.416C87.535,25.522,83.625,24,79.364,24s-8.171,1.522-11.22,4.05H21.98c-1.104,0-2,0.896-2,2s0.896,2,2,2  h42.617c-1.789,2.755-2.84,6.033-2.84,9.556c0,2.257,0.442,4.409,1.22,6.395H21.98c-1.104,0-2,0.896-2,2s0.896,2,2,2h43.206  c3.208,4.363,8.36,7.212,14.178,7.212c9.708,0,17.605-7.898,17.605-17.606c0-3.523-1.051-6.8-2.84-9.556H95c1.104,0,2-0.896,2-2  S96.104,28.05,95,28.05z M79.364,55.212c-7.503,0-13.606-6.104-13.606-13.606C65.758,34.104,71.861,28,79.364,28  c7.502,0,13.605,6.104,13.605,13.605C92.97,49.108,86.866,55.212,79.364,55.212z"
          />
          <path
            d="M9.058,56.048c3.34,0,6.058-2.713,6.058-6.048s-2.718-6.048-6.058-6.048S3,46.665,3,50S5.718,56.048,9.058,56.048z   M9.058,47.952c1.135,0,2.058,0.919,2.058,2.048s-0.923,2.048-2.058,2.048S7,51.129,7,50S7.923,47.952,9.058,47.952z"
          />
          <path
            d="M9.058,76c3.34,0,6.058-2.713,6.058-6.048s-2.718-6.048-6.058-6.048S3,66.617,3,69.952S5.718,76,9.058,76z M9.058,67.904  c1.135,0,2.058,0.919,2.058,2.048S10.192,72,9.058,72S7,71.081,7,69.952S7.923,67.904,9.058,67.904z"
          />
          <path
            d="M58.488,71.952c1.104,0,2-0.896,2-2s-0.896-2-2-2H21.977c-1.104,0-2,0.896-2,2s0.896,2,2,2H58.488z"
          />
        </svg>
      </div>
      <div class="oneicons">
        <svg
          height="20"
   
          
          style="width: 28px"
          version="1.1"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="layer1">
            <path
              d="M 0 3 L 0 14 L 2 14 L 2 17.5 C 2 17.776142 2.2238576 18 2.5 18 C 2.6326005 17.99998 2.7597628 17.94729 2.8535156 17.853516 C 4.1335162 16.57007 6.7070312 14 6.7070312 14 L 20 14 L 20 3 L 0 3 z M 1 4 L 19 4 L 19 13 L 6.2929688 13 L 3 16.292969 L 3 13 L 1 13 L 1 4 z M 3 6 L 3 7 L 14 7 L 14 6 L 3 6 z M 3 8 L 3 9 L 10 9 L 10 8 L 3 8 z M 3 10 L 3 11 L 16 11 L 16 10 L 3 10 z "
            />
          </g>
        </svg>
      </div>

      <div class="oneicons">
        <svg
          height="32"
          id="icon"
          viewBox="0 0 32 32"
          width="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect height="2" width="7" x="16" y="28" />
          <rect height="2" width="14" x="16" y="24" />
          <rect height="2" width="14" x="16" y="20" />
          <polygon
            points="4 20 4 22 8.586 22 2 28.586 3.414 30 10 23.414 10 28 12 28 12 20 4 20"
          />
          <path
            d="M28,8H16L12.5859,4.5859A2.0007,2.0007,0,0,0,11.1716,4H4A2,2,0,0,0,2,6V18H4V6h7.1716l3.4141,3.4141L15.1716,10H28v8h2V10A2,2,0,0,0,28,8Z"
          />
          <rect
            style="fill: none"
            data-name="&lt;Transparent Rectangle&gt;"
            height="32"
            id="_Transparent_Rectangle_"
            width="32"
          />
        </svg>
      </div>
    </div>
    
    <div class="icons">
      <div class="localization">
        <p>Язык:<span @click="languages !== 'en' ? languagesSet('en') : languagesSet('ru')">{{languages == 'en' ? 'Английский' : 'Русский'}}</span></p>
      </div>
      <div class="localization chanelSelect" v-if="false">
        <p>Канал:</p>
        <select @change="changeChanel">
          <option value="site" :selected="['en','ru'].indexOf(languages) !== -1">Сайты</option>
          <option value="sportmaster" :selected="languages == 'sportmaster'">Спортмастер</option>
          <option value="wildberries" :selected="languages == 'wildberries'">Wildberries</option>
        </select>
      </div>
      <div class="oneicons" @click="(mode = !mode), darkmode()">
        <svg
     
          v-if="mode"
          enable-background="new 0 0 32 32"
          id="Outline"
          version="1.1"
          viewBox="0 0 32 32"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title />
          <desc />
          <g>
            <path
              d="M16,26c-5.514,0-10-4.486-10-10S10.486,6,16,6s10,4.486,10,10S21.514,26,16,26z M16,8c-4.411,0-8,3.589-8,8s3.589,8,8,8   s8-3.589,8-8S20.411,8,16,8z"
            />
            <rect height="4" width="2" x="15" />
            <rect
              height="4"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.5269 6.1006)"
              width="2"
              x="5.101"
              y="4.101"
            />
            <rect height="2" width="4" y="15" />
            <rect
              height="2"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -16.5268 11.8995)"
              width="4"
              x="4.101"
              y="24.9"
            />
            <rect height="4" width="2" x="15" y="28" />
            <rect
              height="4"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.7279 25.8994)"
              width="2"
              x="24.9"
              y="23.9"
            />
            <rect height="2" width="4" x="28" y="15" />
            <rect
              height="2"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 3.272 20.1005)"
              width="4"
              x="23.9"
              y="5.101"
            />
          </g>
        </svg>
        <svg
        
          v-else
          fill="currentColor"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"
          />
          <path
            d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"
          />
        </svg>
      </div>

      

      <div class="oneicons">
        <svg
       
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Layer 31" id="Layer_31">
            <path
              class="cls-1"
              d="M27,27H5a1,1,0,0,1-.89-1.45,18.14,18.14,0,0,0,1.89-8V14a10,10,0,0,1,20,0v3.53a18.14,18.14,0,0,0,1.89,8A1,1,0,0,1,27,27ZM6.55,25h18.9A20.14,20.14,0,0,1,24,17.53V14A8,8,0,0,0,8,14v3.53A20.14,20.14,0,0,1,6.55,25Z"
            />
            <path
              class="cls-1"
              d="M16,31a5,5,0,0,1-5-5,1,1,0,0,1,2,0,3,3,0,0,0,.88,2.12,3.08,3.08,0,0,0,4.24,0,1,1,0,0,1,1.42,1.42A5,5,0,0,1,16,31Z"
            />
            <path
              class="cls-1"
              d="M16,6a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0V5A1,1,0,0,1,16,6Z"
            />
            <path
              class="cls-1"
              d="M26,5a2,2,0,1,1,2-2A2,2,0,0,1,26,5Zm0-2h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
            />
          </g>
        </svg>
      </div>
    </div>
    <div class="userRs">
      <img v-if="user.avatar !== null"  :src="`${imgAPI}/${user.avatar}?quality=85&width=300`" />
      <img v-else src="/img/noavatar.jpg" />
      <div class="fl">
        <h4>{{ user.firstname }} {{ user.lastname }}</h4>
        <p v-if="user.job !== null">{{user.job}}</p>
        <p v-else></p>
      </div>
      <div class="settings">
        <svg
          v-if="!menuUser"
      
          data-name="Livello 1"
          id="Livello_1"
          viewBox="0 0 128 128"
          xmlns="http://www.w3.org/2000/svg"
          @click="$router.push(`/adminka/user/${user._id}`)"
        >
          <title />
          <path
            d="M64,39A25,25,0,1,0,89,64,25,25,0,0,0,64,39Zm0,44A19,19,0,1,1,83,64,19,19,0,0,1,64,83Z"
          />
          <path
            d="M121,48h-8.93a1,1,0,0,1-.94-.68,49.9,49.9,0,0,0-2-4.85,1,1,0,0,1,.18-1.15L115.62,35a7,7,0,0,0,0-9.9L102.89,12.38a7,7,0,0,0-9.9,0l-6.31,6.31a1,1,0,0,1-1.15.18,49.76,49.76,0,0,0-4.85-2,1,1,0,0,1-.68-.94V7a7,7,0,0,0-7-7H55a7,7,0,0,0-7,7v8.93a1,1,0,0,1-.68.94,49.9,49.9,0,0,0-4.85,2,1,1,0,0,1-1.15-.18L35,12.38a7,7,0,0,0-9.9,0L12.38,25.11a7,7,0,0,0,0,9.9l6.31,6.31a1,1,0,0,1,.18,1.15,49.76,49.76,0,0,0-2,4.85,1,1,0,0,1-.94.68H7a7,7,0,0,0-7,7V73a7,7,0,0,0,7,7h8.93a1,1,0,0,1,.94.68,49.9,49.9,0,0,0,2,4.85,1,1,0,0,1-.18,1.15L12.38,93a7,7,0,0,0,0,9.9l12.73,12.73a7,7,0,0,0,9.9,0l6.31-6.31a1,1,0,0,1,1.15-.18,49.76,49.76,0,0,0,4.85,2,1,1,0,0,1,.68.94V121a7,7,0,0,0,7,7H73a7,7,0,0,0,7-7v-8.93a1,1,0,0,1,.68-.94,49.9,49.9,0,0,0,4.85-2,1,1,0,0,1,1.15.18L93,115.62a7,7,0,0,0,9.9,0l12.73-12.73a7,7,0,0,0,0-9.9l-6.31-6.31a1,1,0,0,1-.18-1.15,49.76,49.76,0,0,0,2-4.85,1,1,0,0,1,.94-.68H121a7,7,0,0,0,7-7V55A7,7,0,0,0,121,48Zm1,25a1,1,0,0,1-1,1h-8.93a7,7,0,0,0-6.6,4.69,43.9,43.9,0,0,1-1.76,4.26,7,7,0,0,0,1.35,8l6.31,6.31a1,1,0,0,1,0,1.41L98.65,111.38a1,1,0,0,1-1.41,0l-6.31-6.31a7,7,0,0,0-8-1.35,43.88,43.88,0,0,1-4.27,1.76,7,7,0,0,0-4.68,6.6V121a1,1,0,0,1-1,1H55a1,1,0,0,1-1-1v-8.93a7,7,0,0,0-4.69-6.6,43.9,43.9,0,0,1-4.26-1.76,7,7,0,0,0-8,1.35l-6.31,6.31a1,1,0,0,1-1.41,0L16.62,98.65a1,1,0,0,1,0-1.41l6.31-6.31a7,7,0,0,0,1.35-8,43.88,43.88,0,0,1-1.76-4.27A7,7,0,0,0,15.93,74H7a1,1,0,0,1-1-1V55a1,1,0,0,1,1-1h8.93a7,7,0,0,0,6.6-4.69,43.9,43.9,0,0,1,1.76-4.26,7,7,0,0,0-1.35-8l-6.31-6.31a1,1,0,0,1,0-1.41L29.35,16.62a1,1,0,0,1,1.41,0l6.31,6.31a7,7,0,0,0,8,1.35,43.88,43.88,0,0,1,4.27-1.76A7,7,0,0,0,54,15.93V7a1,1,0,0,1,1-1H73a1,1,0,0,1,1,1v8.93a7,7,0,0,0,4.69,6.6,43.9,43.9,0,0,1,4.26,1.76,7,7,0,0,0,8-1.35l6.31-6.31a1,1,0,0,1,1.41,0l12.73,12.73a1,1,0,0,1,0,1.41l-6.31,6.31a7,7,0,0,0-1.35,8,43.88,43.88,0,0,1,1.76,4.27,7,7,0,0,0,6.6,4.68H121a1,1,0,0,1,1,1Z"
          />
        </svg>
        <svg
          height="512px"
          v-else
          @click="menuUser = false"
          style="enable-background: new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          width="512px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="_x37_12-_close__x2C__cross__x2C__cancel__x2C_">
            <g>
              <line
                style="
                  fill: none;
                  stroke: #000000;
                  stroke-width: 13.4167;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 2.6131;
                "
                x1="486.21"
                x2="26.739"
                y1="26.814"
                y2="486.139"
              />
              <line
                style="
                  fill: none;
                  stroke: #000000;
                  stroke-width: 13.4167;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 2.6131;
                "
                x1="486.21"
                x2="26.739"
                y1="486.139"
                y2="26.814"
              />
            </g>
          </g>
          <g id="Layer_1" />
        </svg>
      </div>
    </div>
  </header>

  <transition name="slimeright">
    <menuViewBlock v-if="menuUser" />
  </transition>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import menuViewBlock from "@/components/general/menuuser.vue";
import {languages,languagesSet} from "@/utils/general"
import { useRouter, useRoute } from "vue-router";

    const mode = ref(false);
    const { getters } = useStore();
    const user = getters["auth/user"];
    const menuUser = ref(false);
    const router = useRouter();
    const route = useRoute();
    const imgAPI = process.env.VUE_APP_IMAGES;
    const darkmode = () => {
      if (mode.value) {
        document.querySelector("html").classList.add("dark-theme");
        localStorage.setItem("darktheme", true);
      } else {
        document.querySelector("html").classList.remove("dark-theme");
        localStorage.setItem("darktheme", false);
      }
    };
    onMounted(() => {
      if (JSON.parse(localStorage.getItem("darktheme"))) {
        mode.value = true;
      } else {
        mode.value = false;
      }
      darkmode();
    });

    const changeChanel = (e) => {
      if(e.target.value !== 'site'){
        languagesSet(e.target.value)
        return
      }
      languagesSet('ru')
    }


</script>

<style scoped>
header {
  height: 60px;
  background: var(--blue);
  display: grid;
  align-items: center;
  grid-template-columns: 180px 1fr 1fr auto;
  border-bottom: 1px solid #dadada;
  gap: 10px;
}

.logotip {
  display: grid;
  justify-items: center;
}

.logotip img{
  filter: invert(1)
}

.userRs {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 5px;
  justify-self: right;
  z-index: 999;
  position: relative;
}
.userRs img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.settings {
  margin-left: 10px;
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;
}

.settings svg {
  width: 25px;
  height: auto;
}

.fl {
}
.fl h4 {
  font-weight: 200;
  color: var(--white);
}
.fl p {
  color: var(--white);
  font-weight: 100;
  font-size: 12px;
}

.icons {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: max-content;
  justify-self: right;
  margin-right: 20px;
  gap: 10px;
}
.oneicons {
  cursor: pointer;
  transition: 200ms;
  height: auto;
  display: grid;
  align-items: center;
  justify-items: center;
}

.oneicons:hover {
  transform: translateY(-2px);
}

.oneicons svg {
  width: 25px;
  height: auto;
}
.oneicons svg path{
 fill:var(--white)
}
.oneicons svg path,.oneicons svg rect,.oneicons svg polygon,.settings svg path{
 fill:var(--white)
}

.blockfunction {
  display: grid;
  grid-auto-flow: column;
  width: max-content;
  gap: 10px;
}

.twogridicons {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-items: center;
  border-radius: 10px;
  margin-right: 10px;
}

.twogridicons svg path {
  fill: var(--blue);
}
.twogridicons p {
  font-weight: 300;
  color: var(--blue);
}

.localization{
  margin-right: 25px;
}
.localization p {
  color:var(--white)
}
.localization span {
  cursor: pointer;
  transition: 200ms;

}

.localization span:hover{
  opacity: 0.5;
}

.chanelSelect{
    display: grid;
    grid-template-columns:auto 150px;
    align-items: center;
    gap: 5px;
}
</style>
