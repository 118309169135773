import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/payment",
    name: "payment",
    title: "Оплаты Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Оплаты",
        item: defineAsyncComponent(() =>
          import("@/components/service/payment/item.vue")
        ),
      },
      collection: "payment",
      populate: [],
      filter: {
        object: ["id"],
        text: "Найти по номеру телефона",
        placeholder: "введите номер телефона клиента",
        menufilter: [
          { name: "Исходящие", key: "status", action: true },
          { name: "Входящие", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/avansandbonus",
    name: "avansandbonus",
    title: "Начисления Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Начисления",
        item: defineAsyncComponent(() =>
          import("@/components/service/payment/item.vue")
        ),
      },
      collection: "avansandbonus",
      populate: [],
      filter: {
        object: ["id"],
        text: "Найти по номеру телефона",
        placeholder: "введите номер телефона клиента",
        menufilter: [
          { name: "Все", key: "status", action: true },
          { name: "Аванс", key: "status", action: false },
          { name: "Бонус", key: "status", action: false },
        ],
      },
    },
  },
];
